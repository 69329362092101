<template>
  <div>
    <div class="levels">
      <div class="level level-right">
        <button class="button is-success" :class="{'is-loading': loading}" @click="importData">Importer</button>
      </div>
    </div>

    <div class="py-1" v-if="loading">
      <progress v-show="percent != null" class="progress is-success" :value="percent" max="100">{{ percent }}%</progress>
      <progress v-show="percent == null" class="progress is-success" max="100"></progress>
    </div>

    <div class="py-1" v-if="linesCount > 1000">
      <div class="message is-warning">
        <div class="message-body">
          Un grand nombre de lignes peut prendre du temps à être importé. La durée d'importation est estimée à environ <b>{{ validLines / importSpeed }}</b> secondes.
        </div>
      </div>
    </div>

    <div class="py-2">
      <div class="message is-danger" v-if="linesCount > validLines">
        <div class="message-body">
          <b>Attention!</b> La table contient <b>{{ linesCount-validLines }}</b> ligne(s) en erreur. Les lignes contenant des erreurs seront ignorées lors de l'importation.
        </div>
      </div>
    </div>

    <div :class="{overlay: loading}">
      <hot-table ref="spreadsheet" :settings="settings" style="min-height: 50vh; overflow: auto;"></hot-table>
      <div class="shadow"></div>
    </div>
  </div>
</template>
<script>
import { HotTable } from '@handsontable/vue';

export default {
  name: 'handsontable',
  components: {
    HotTable
  },
  props: {
    columns: {},
    startRows: {
      type: Number,
      default: 300
    },
    import: Function,
    allowExtraColumns: {
      type: Boolean,
      default: false
    },
    extraColumnsValidation: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      loading: false,
      percent: null,
      importSpeed: 15,
      settings: {
        data: [], // Initial data during init.
        colHeaders: [],
        rowHeaders: true,
        stretchH: 'all',
        columns: [],
        afterChange: () => {
          if (this.$refs.spreadsheet.hotInstance) {
            let newCells = []
            this.$refs.spreadsheet.hotInstance.getSourceData().forEach((row, i) => {
              row.forEach((cell, j) => {
                if(!cell || typeof cell == "number") return

                let value = cell.replaceAll(/[\r\t\n]/g, '').trim()
                if(cell != value) {
                  newCells.push([i, j, value])
                }
              })
            })

            if(newCells.length > 0) {
              this.$refs.spreadsheet.hotInstance.setDataAtCell(newCells)
            }
            this.data = this.$refs.spreadsheet.hotInstance.getSourceData().map(d => d.map(c => c && typeof(c) !== 'number' ? c.trim() : c));
          }
          this.validate();
        },
        afterRender: () => {
          this.validate();
        },
        beforePaste: (data) => {
          if (this.allowExtraColumns && data[0][0] === this.columnsDefinition[1].label) {
            for(let i =0; i < data[0].length; i++) {
              if(!this.columnsDefinition[i+1]) {
                this.columnsDefinition.push({
                  key: data[0][i],
                  label: this.extraColumnsValidation.includes(data[0][i].toLowerCase().replace(/ /g, '_')) ? data[0][i] : 'colonne non trouvee'
                })
                this.settings.columns.push({
                  key: data[0][i],
                  label: data[0][i]
                })

                this.settings.colHeaders.push(data[0][i])
              }
            }
          }
          this.validate();
        }
      },
      validLines: 0,
      columnsDefinition: [],
      data: [] // Data of the spreadsheet
    };
  },
  mounted() {
    this.columnsDefinition = [
      {label: 'Statut import', readOnly: true}
    ];

    this.columns.forEach((c) => this.columnsDefinition.push(c));

    this.settings.colHeaders = this.columnsDefinition.map((c) => c.label);
    this.settings.columns = this.columnsDefinition.map((c) => {
          return {
            label: c.label,
            readOnly: c.readOnly,
            // wordWrap: false,
            type: c.type,
          };
        });

    const cells = [
      this.settings.colHeaders
    ];

    for(let i = 0; i < this.startRows; i++) {
      const row = Array(this.columnsDefinition.length).fill('');
      cells.push(row);
    }

    this.settings.data = cells;
  },
  computed: {
    linesCount () {
      return this.data.reduce((acc, val) => acc + (val.slice(1).some((e) => e != '') ? 1 : 0), 0);
    }
  },
  methods: {
    runTimer(totalTime, timeLeft) {
      if(totalTime == timeLeft) {
        this.percent = 0;
      }

      setTimeout(() => {
        if(timeLeft > 0) {
          this.percent = ((totalTime - timeLeft) / totalTime) * 100;
        }
        else {
          this.percent = null;
        }
        if(this.loading) {
          this.runTimer(totalTime, timeLeft - 0.5);
        }
      }, 500);
    },
    validate() {
      if(!this.$refs.spreadsheet.hotInstance) {
        return;
      }
      const spreadsheet = this.$refs.spreadsheet.hotInstance;

      const lineCount = this.data.length;
      this.validLines = 0;
      for(let row = 0; row < lineCount; row++) {
        const rowData = this.data[row];

        // Skip empty lines
        if(!rowData.slice(1).some((e) => e != '')) {
          continue;
        }

        let validLine = true;
        // Validate each columns.
        for(let col = 1; col < this.columnsDefinition.length; col++) {
          const cellData = rowData[col];
          const colDef = this.columnsDefinition[col];

          let isValid = false;
          if(row == 0) {
            isValid = (cellData && (cellData == colDef.label || this.extraColumnsValidation.includes(cellData.toLowerCase().replace(/ /g, '_'))));
          } else {
            if(this.columnsDefinition[col].required && cellData == '') {
              isValid = false;
            }
            else
            if(this.columnsDefinition[col].validator) {
              isValid = this.columnsDefinition[col].validator(cellData)
            }
            else {
              isValid = true;
            }
          }

          if(validLine) {
            validLine = isValid;
          }

          // Change cell rendering if possible.
          const cell = spreadsheet.getCell(row, col);
          if(!cell) {
            continue;
          }
          cell.style.background = isValid ? '#CEC' : '#F00';
        }
        if(validLine) {
          this.validLines++;
        }
      }
    },
    importData() {
      if(!this.$refs.spreadsheet.hotInstance) {
        return;
      }
      const spreadsheet = this.$refs.spreadsheet.hotInstance;

      this.loading = true;

      // Preparing data.
      let payload = this.data
      // Assign index
      .map((d, idx) => {
        d[0] = idx;
        return d;
      })
      // Remove first line
      .slice(1)
      // Remove empty lines
      .filter((d) => d.slice(1).some((c) => c != ''))
      // Remove invalid lines
      .filter((rowData) => {
        let isValidFinal = true;
        for(let col = 1; col < rowData.length; col++) {
          const cellData = rowData[col];

          let isValid = false;
          if(this.columnsDefinition[col].required && cellData == '') {
            isValid = false;
          }
          else
          if(this.columnsDefinition[col].validator) {
            isValid = this.columnsDefinition[col].validator(cellData)
          }
          else {
            isValid = true;
          }
          isValidFinal = isValidFinal && isValid
        }
        return isValidFinal;
      })
      // Convert array items to entity objects
      .map((rowData) => {
        let obj = {};
        obj['index'] = rowData[0];

        if(this.allowExtraColumns) {
          obj['extraColumns'] = {}
        }

        for(let i = 1; i < rowData.length; i++) {
          // Check for extra columns
          if(this.columns[i-1]) {
            let key = this.columnsDefinition[i].key;
            obj[key] = rowData[i];
          } else {
            obj['extraColumns'][this.data[0][i].toLowerCase().replace(/ /g, '_')] = rowData[i]
          }
        }
        return obj;
      })

      const time = this.validLines / this.importSpeed;
      this.runTimer(time, time);

      this.import({
        data: payload
      })
      .then((response) => {
        const d = response.data;
        let changes = [];
        d.imported.forEach((row) => {
          changes.push([row,0, 'Importée'])
        })

        d.duplicated.forEach((row) => {
          changes.push([row,0, 'Doublon'])
        })

        d.invalid.forEach((row) => {
          changes.push([row,0, 'Invalide'])
        })

        d.updated.forEach((row) => {
          changes.push([row,0, 'Mis à jour'])
        })

        // Apply changes
        spreadsheet.setDataAtCell(changes);
      })
      .finally(() => this.loading = false);
    }
  }
}
</script>
<style>
.overlay {
  position: relative;
}

.overlay .shadow {
  position: absolute;
  z-index: 150;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
}

.ht_clone_top {
  z-index: 29;
}
</style>
